<template>
  <v-container fluid>
    <v-form>
      <v-row>
        <v-col
          md="6"
          cols="12"
        >
          <base-material-card
            color="primary"
            icon="mdi-flash-alert-outline"
            inline
            class="px-5 py-3"
            style="min-height: 900px"
          >
            <template v-slot:after-heading>
              <div class="display-1 font-weight-light">Parâmetros básicos</div>
            </template>
            <v-row class="mt-5">
              <v-col cols="12">
                <v-text-field
                  label="Título (opcional)"
                  outlined
                  v-model="titulo"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <status-conexao-servidor
                  v-if="servidor"
                  :servidor="servidor"
                  @conectadoServidor:changed="
                    ($event) => (conectadoServidor = $event)
                  "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <select-server
                  :type="'outlined'"
                  @serverSelected="(servidor) => (this.servidor = servidor)"
                />
              </v-col>
              <v-col
                cols="6"
                v-if="servidor && conectadoServidor"
              >
                <select-database
                  :type="'outlined'"
                  :onlyDatabaseWithPrefix="databasePrefix"
                  :selectedServer="servidor"
                  @databaseSelected="(database) => (this.database = database)"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <select-all-bdgd-versions
                  label="Versão da BDGD"
                  type="outlined"
                  :showIcon="false"
                  :companyId="companyId"
                  @bdgdVersionSelected="
                    (bdgdVersao) => (this.bdgdVersao = bdgdVersao)
                  "
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-switch
                  color="primary"
                  v-model="flagAllCtmts"
                  @change="selectAllCtmts($event)"
                  :label="`Selecionar todas CTMTS (${ctmts.length})`"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  placeholder="Cole aqui os códigos de CTMT, preferencialmente um por linha"
                  v-model="ctmtFilter"
                  rows="10"
                  no-resize
                  clearable
                />
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <base-material-card
            color="primary"
            icon="mdi-square-edit-outline"
            inline
            class="px-5 py-3"
            style="min-height: 900px"
          >
            <template v-slot:after-heading>
              <div class="display-1 font-weight-light">Parâmetros extras</div>
            </template>
            <v-row class="mt-5">
              <v-col cols="6">
                <v-text-field
                  v-model="maximoTentativas"
                  title="Ao atingir esse valor máximo de tentativas, o processo de cálculo finalizará com o status FALHA"
                  label="Máximo de tentativas para o cálculo"
                  type="number"
                  min="1"
                  max="5"
                  step="1"
                  outlined
                />
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  v-model="
                    parametros.calcular_ctmt_circ_atip_resultante_nao_zero
                  "
                  color="primary"
                  label="Calcular também para CTMT com CircAtipResultante <> 0"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-divider />
            <v-row class="mt-5">
              <v-col cols="6">
                <v-text-field
                  v-model="parametros.tensao_minima"
                  label="Tensão Mínima (pu)"
                  type="number"
                  outlined
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="parametros.modelo_de_carga"
                  label="Modelos de carga (1, 2, 3)"
                  :items="[
                    { value: 1, label: '1 - Modelo de carga 2 e 3' },
                    { value: 2, label: '2 - Modelo de carga 1' },
                    { value: 3, label: '3 - Modelo de carga 3' }
                  ]"
                  item-value="value"
                  item-text="label"
                  outlined
                  class="mb-0 pb-0"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-checkbox
                  v-model="parametros.medicao_completa"
                  color="primary"
                  label="Medição Completa"
                  hide-details
                />
                <v-checkbox
                  v-model="parametros.reinicializa_cargas_nao_tecnicas"
                  color="primary"
                  label="Reinicializa Cargas Não Técnicas"
                  hide-details
                />
                <v-checkbox
                  v-model="parametros.convergencia_de_perda_nao_tecnica"
                  color="primary"
                  label="Convergência de Perda Não Técnica"
                  hide-details
                />
                <v-checkbox
                  v-model="parametros.transformadores_abnt"
                  color="primary"
                  label="Transformadores ABNT"
                  hide-details
                />
                <v-checkbox
                  v-model="parametros.adequacao_tensao_minima_das_cargas_mt"
                  color="primary"
                  label="Adequação Tensão Mínima das Cargas MT (0,93 pu)"
                  hide-details
                />
                <v-checkbox
                  v-model="parametros.adequacao_tensao_minima_das_cargas_bt"
                  color="primary"
                  label="Adequação Tensão Mínima das Cargas BT (0,92 pu)"
                  hide-details
                />

                <v-checkbox
                  v-model="
                    parametros.limitar_maxima_tensao_de_barras_e_reguladores
                  "
                  color="primary"
                  label="Limitar Máxima Tensão de Barras e Reguladores (1,05 pu)"
                  hide-details
                />
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  v-model="parametros.limitar_ramal"
                  color="primary"
                  label="Limitar o Ramal (30 m)"
                  hide-details
                />
                <v-checkbox
                  v-model="parametros.utilizar_tap_nos_transformadores"
                  color="primary"
                  label="Utilizar Tap nos Transformadores"
                  hide-details
                />
                <v-checkbox
                  v-model="parametros.limitar_cargas_bt"
                  color="primary"
                  label="Limitar Cargas BT (Potência ativa do transformador)"
                  hide-details
                />
                <v-checkbox
                  v-model="parametros.eliminar_transformadores_vazios"
                  color="primary"
                  label="Eliminar Transformadores Vazios"
                  hide-details
                />
                <v-checkbox
                  v-model="parametros.neutralizar_transformadores_de_terceiros"
                  color="primary"
                  label="Neutralizar Transformadores de Terceiros"
                  hide-details
                />
                <v-checkbox
                  v-model="parametros.neutralizar_redes_de_terceiros"
                  color="primary"
                  label="Neutralizar Redes de Terceiros (MT/BT)"
                  hide-details
                />
              </v-col>
            </v-row>
          </base-material-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            color="primary"
            min-width="100"
            style="float: right"
            class="mt-n5 mr-0"
            @click="executarCalculo"
            :disabled="!podeExecutarCalculo"
            :loading="loading"
          >
            Executa MT-BT
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import CtmtsService from '@/services/CtmtsService';
import ProgGeoPerdasAneelService from '@/services/ProgGeoPerdasAneelService';
import SelectAllBdgdVersions from '@/components/general/SelectAllBdgdVersions.vue';
import SelectServer from '@/components/geo-perdas/servidores-bancos-dados/SelectServer.vue';
import SelectDatabase from '@/components/geo-perdas/servidores-bancos-dados/SelectDatabase.vue';
import StatusConexaoServidor from '@/components/geo-perdas/servidores-bancos-dados/StatusConexaoServidor.vue';
import routes from '@/store/modules/routes';

export default {
  components: {
    SelectAllBdgdVersions,
    SelectServer,
    SelectDatabase,
    StatusConexaoServidor
  },
  data: () => ({
    loading: false,
    servidor: null,
    bdgdVersao: null,
    database: null,
    conectadoServidor: false,
    ctmtFilter: null,
    ctmts: [],
    flagAllCtmts: false,
    titulo: null,
    maximoTentativas: 1,
    parametros: {
      tensao_minima: 0.6,
      modelo_de_carga: 1,
      reinicializa_cargas_nao_tecnicas: true,
      medicao_completa: false,
      convergencia_de_perda_nao_tecnica: true,
      transformadores_abnt: true,
      adequacao_tensao_minima_das_cargas_mt: true,
      adequacao_tensao_minima_das_cargas_bt: true,
      limitar_maxima_tensao_de_barras_e_reguladores: true,
      limitar_ramal: true,
      utilizar_tap_nos_transformadores: true,
      limitar_cargas_bt: true,
      eliminar_transformadores_vazios: true,
      neutralizar_transformadores_de_terceiros: true,
      neutralizar_redes_de_terceiros: true,
      calcular_ctmt_circ_atip_resultante_nao_zero: false
    }
  }),
  mounted() {
    this.getCtmts();
  },
  computed: {
    companyId() {
      return this.$store.getters.getCompanyId;
    },
    userLoggedCompany() {
      return this.$store.getters.getSelectedCompany;
    },
    databasePrefix() {
      return `GeoPerdas${this.userLoggedCompany.acronym}`;
    },
    ctmtsSelecionadas() {
      return this.ctmtFilterToArray(this.ctmtFilter);
    },
    podeExecutarCalculo() {
      return !!(
        this.servidor &&
        this.conectadoServidor &&
        this.bdgdVersao &&
        this.database &&
        this.ctmtsSelecionadas.length > 0
      );
    }
  },
  methods: {
    getCtmts() {
      if (!this.bdgdVersao) return;
      CtmtsService.getCtmtsByBdgdVersaoId(this.bdgdVersao.id)
        .then(({ data }) => {
          this.ctmts = data;
          this.flagAllCtmts = false;
        })
        .catch(() => {
          this.$toast.error(
            'Erro ao buscar os CTMTs da versão da BDGD selecionada.',
            '',
            {
              position: 'topRight'
            }
          );
        });
    },
    selectAllCtmts(event) {
      event
        ? (this.ctmtFilter = this.ctmts.map((ctmt) => ctmt.cod_id).join('\n'))
        : (this.ctmtFilter = null);
    },
    ctmtFilterToArray(ctmtFilterTxt) {
      let ctmtFilterArray = [];
      if (ctmtFilterTxt === null || ctmtFilterTxt === '')
        return ctmtFilterArray;
      ctmtFilterTxt = ctmtFilterTxt
        .replaceAll("'", '')
        .replaceAll('"', '')
        .replaceAll(',', '\n')
        .replaceAll(';', '\n');
      ctmtFilterArray = ctmtFilterTxt
        .split('\n')
        .map((ctmt) => ctmt.trim())
        .filter((ctmt) => ctmt !== '');
      return ctmtFilterArray;
    },
    executarCalculo() {
      this.loading = true;
      const data = {
        nome: this.titulo,
        maximo_tentativas: this.maximoTentativas,
        parametros: this.parametros,
        servidor_id: this.servidor.id,
        database_perdas: this.database,
        ctmts: this.ctmtsSelecionadas,
        bdgd_versao_id: this.bdgdVersao.id
      };
      ProgGeoPerdasAneelService.save(data)
        .then(() => {
          this.$toast.success(
            'Execução do cálculo de perdas agendado com sucesso.',
            '',
            {
              position: 'topRight'
            }
          );
          this.$router.replace({
            name: routes.state.appRoutes['PROG_GEOPERDAS_ANEEL']
          });
        })
        .catch(() => {
          this.$toast.error(
            'Erro ao agendar a execução do cálculo de perdas.',
            '',
            {
              position: 'topRight'
            }
          );
        })
        .finally(() => (this.loading = false));
    }
  },
  watch: {
    bdgdVersao() {
      this.getCtmts();
    }
  }
};
</script>
